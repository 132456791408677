import React, { useState } from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables);

const BarChartHorizontaly = () => {
    const [data] = useState({
        labels: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Order Create',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#001633',
                borderColor: '#001633',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#001633',
                pointBackgroundColor: '#001633',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#001633',
                pointHoverBorderColor: '#001633',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: [21, 23, 43, 32, 54, 34, 65, 76, 78, 87, 98, 87, 43],
            },
            {
                label: 'Order Dispatch',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#85FFD0',
                borderColor: '#85FFD0',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#85FFD0',
                pointBackgroundColor: '#85FFD0',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#85FFD0',
                pointHoverBorderColor: '#85FFD0',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: [34, 22, 45, 55, 45, 85, 66, 98, 77, 33, 78, 90, 100],
            },
            {
                label: 'Cancle Orders',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#da2f68',
                borderColor: '#da2f68',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#da2f68',
                pointBackgroundColor: '#da2f68',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#da2f68',
                pointHoverBorderColor: '#da2f68',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: [21, 11, 45, 78, 87, 90, 54, 78, 45, 99, 44, 77, 78],
            },
            {
                label: 'product Returns',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#ff5733',
                borderColor: '#ff5733',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#ff5733',
                pointBackgroundColor: '#ff5733',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#ff5733',
                pointHoverBorderColor: '#ff5733',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: [65, 25, 85, 82, 14, 47, 85, 58, 65, 23, 25, 54, 96],
            },
        ],
    });
    return (
        <div className="bg-white">
            <h4>Yearly Sale's</h4>
            <Line data={data} options={{ responsive: true }} />
        </div>
    )
}

export default BarChartHorizontaly