import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { AiFillMobile, AiTwotoneMail } from 'react-icons/ai'
import { FaCity, FaLocationDot } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addRegistration, fetchHrDataFromSuperAdmin, getDataAPIToPincode } from '../utils/api'
import { FaHouseDamage } from 'react-icons/fa'
import { TbBuildingEstate, TbMapPinCode } from "react-icons/tb";
import validateWHForm from '../validations/whvalidation'
import { BiSolidHandRight } from "react-icons/bi";
import { GiIndiaGate } from "react-icons/gi";


const AddWereHouse = () => {
    const formRef = React.useRef(null);
    const navigate = useNavigate();
    const [pindata, setPindata] = useState([]);
    const [values, setUserValue] = useState({});
    const [hiddend, setHiddenValue] = useState({});
    const [company, setCompany] = useState([]);

    useEffect(() => {
        getActiveCompany()
    }, [])

    const getActiveCompany = () => {
        fetchHrDataFromSuperAdmin('super-admin-get-active-company').then((res) => {
            if (res.data.status === 1) {
                setCompany(...[res.data.company])
            }
            else {
                toast.warning("Check you connection");
            }
        })
    }

    const handlePincode = e => {
        if (e.target.value.length === 6) {
            const pincode = e.target.value;
            getDataAPIToPincode('super-admin-get-data-according-to-pincode', pincode).then((res) => {
                if (res?.data.status === 1) {
                    setPindata(...[res?.data.pincode]);
                    setHiddenValue({ ...hiddend, whouse_city: res?.data.pincode.city_name, whouse_state: res?.data.pincode.state_name, whouse_country: res?.data.pincode.country_name });
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
    }


    const [errors, setErrors] = useState({});

    const handleInputs = async (e) => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleAddWHouse = async (e) => {
        e.preventDefault();
        //setErrors(validateWHForm(values))
        const data = { whouse_name: values.whouse_name, whouse_mobile: values.whouse_mobile, whouse_email: values.whouse_email, whouse_pincode: values.whouse_pincode, whouse_city: hiddend.whouse_city, whouse_state: hiddend.whouse_state, whouse_country: hiddend.whouse_country, whouse_address: values.whouse_address, whouse_company: values.whouse_company }
        
        if (data) {
            addRegistration('super-admin-add-werehouse', data).then((res) => {
                if (res.data.status === 1) {
                    navigate("/ware-house");
                    toast.success("Ware house has been added successfully!!")
                }
                else if (res.data.status === 2) {
                    toast.warning("Ware house already Exit!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        else {
            console.log("all input values errors", errors);
        }
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:SUPER ADMIN::ADD WERE HOUSE</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-12">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaHouseDamage className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD Were House</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="POST" onSubmit={handleAddWHouse} ref={formRef}>
                                <div className="col-6">
                                    <label htmlFor="whouse_name" className="form-label">Were House Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaHouseDamage />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="whouse_name" name="whouse_name" placeholder="Were House Name" onChange={handleInputs} required={true} />
                                    </div>
                                    {errors.whouse_name && <p style={{ color: "red" }}><BiSolidHandRight /> {errors.whouse_name}</p>}
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_mobile" className="form-label">Mobile No</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiFillMobile />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="whouse_mobile" name="whouse_mobile" placeholder="Mobile No" onChange={handleInputs} maxLength={10} minLength={10} inputMode="numeric" required={true} />
                                    </div>
                                    {errors.whouse_mobile && <p style={{ color: "red" }}><BiSolidHandRight /> {errors.whouse_mobile}</p>}
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_email" className="form-label">Email Address</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiTwotoneMail />
                                        </span>
                                        <input type="email" className="form-control border-start-0" id="whouse_email" name="whouse_email" placeholder="Email Address" onChange={handleInputs} required={true} />
                                    </div>
                                    {errors.whouse_email && <p style={{ color: "red" }}><BiSolidHandRight /> {errors.whouse_email}</p>}
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_pincode" className="form-label">Pincode</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <TbMapPinCode />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="whouse_pincode" name="whouse_pincode" placeholder="Pincode" onKeyUp={handlePincode} onChange={handleInputs} maxLength={6} minLength={6} required={true} inputMode="numeric" pattern="[0-9]{6}" />
                                    </div>
                                    {errors.whouse_pincode && <p style={{ color: "red" }}><BiSolidHandRight /> {errors.whouse_pincode}</p>}
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_city" className="form-label">City Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaCity />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="whouse_city" name="whouse_city" placeholder="City Name" value={pindata?.city_name} required={true} readOnly={true} />
                                    </div>
                                    {errors.whouse_city && <p style={{ color: "red" }}><BiSolidHandRight /> {errors.whouse_city}</p>}
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_state" className="form-label">State Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <TbBuildingEstate />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="whouse_state" name="whouse_state" value={pindata?.state_name} placeholder="State Name" onChange={handleInputs} required={true} readOnly={true} />
                                    </div>
                                    {errors.whouse_state && <p style={{ color: "red" }}><BiSolidHandRight /> {errors.whouse_state}</p>}
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_country" className="form-label">Country Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <GiIndiaGate />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="whouse_country" name="whouse_country" value={pindata?.country_name} placeholder="Country Name" onChange={handleInputs} required={true} readOnly={true} />
                                    </div>
                                    {errors.whouse_state && <p style={{ color: "red" }}><BiSolidHandRight /> {errors.whouse_state}</p>}
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_country" className="form-label">Select Company</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <TbBuildingEstate />
                                        </span>
                                        <select className="form-control border-start-0" id="whouse_company" name="whouse_company" required={true} onChange={handleInputs}>
                                            <option value="">Select Company</option>
                                            {company?.map((company) => (
                                                <option value={company.company_id}>{company.company_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {errors.whouse_state && <p style={{ color: "red" }}><BiSolidHandRight /> {errors.whouse_state}</p>}
                                </div>
                                <div className="col-12">
                                    <label htmlFor="whouse_address" className="form-label">Address</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaLocationDot />
                                        </span>
                                        <textarea className="form-control border-start-0" id="whouse_address" name="whouse_address" placeholder="Address" onChange={handleInputs} required={true}>
                                        </textarea>
                                    </div>
                                    {errors.whouse_address && <p style={{ color: "red" }}><BiSolidHandRight /> {errors.whouse_address}</p>}
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddWereHouse