import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { AiFillDelete, AiFillEdit, AiOutlinePlus } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { activeStatus, fetchHrDataFromSuperAdmin, removeDataFromList } from '../utils/api'
import LoadingSpinner from '../components/LoadingSpinner'
import Swal from 'sweetalert2'

const WereHouse = () => {
    const [werehouse, setWerehouse] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAllWerehouse()
    }, [])

    const getAllWerehouse = () => {
        setIsLoading(true)
        fetchHrDataFromSuperAdmin('super-admin-get-werehouse').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setWerehouse(...[res.data.werehouse]);
            }
            else {
                setIsLoading(false);
                toast.warning("Check your connection");
            }
        })
    }
    const handleWereHouseActive = (werehouseid) => {
        activeStatus('super-admin-active-werehouse-status', werehouseid).then((res) => {
            if (res.data.status === 1) {
                getAllWerehouse()
            }
        })
    }
    const handleWereHouseInactive = (werehouseid) => {
        activeStatus('super-admin-inactive-werehouse-status', werehouseid).then((res) => {
            if (res.data.status === 1) {
                getAllWerehouse()
            }
        })
    }
    const handleWereHouseRemove = (werehouseid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('super-admin-remove-werehouse', werehouseid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getAllWerehouse()
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    return (
        <div className="companypage">
            <Helmet>
                <title>SATKARTAR:ADMIN::WERE HOUSE</title>
            </Helmet>
            <ContentWrapper>
                <div className="card hrpages">
                    <h4 className="mb-0 text-uppercase">
                        All Were House
                        <Link to="/add-ware-house" className="btn btn-sm btn-primary pull-right"><AiOutlinePlus /> Add Were House</Link>
                    </h4>
                    <div className="table pagetable table-responsive">
                        <table id="example" className="table table-striped" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Company</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Country</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <LoadingSpinner />
                                    :
                                    werehouse?.map((werehouse, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{werehouse?.whouse_name}</td>
                                            <td>{werehouse?.whouse_email}</td>
                                            <td>{werehouse?.whouse_mobile}</td>
                                            <td>{werehouse?.company_name}</td>
                                            <td>{werehouse?.whouse_city}</td>
                                            <td>{werehouse?.whouse_state}</td>
                                            <td>{werehouse?.whouse_country}</td>
                                            <td>
                                                {(() => {
                                                    if (werehouse?.werehouse_status === "Active") {
                                                        return (
                                                            <span className="badge bg-success hrstatus" onClick={() => handleWereHouseActive(werehouse?.werehouse_id)}>{werehouse?.werehouse_status}</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="badge bg-danger hrstatus" onClick={() => handleWereHouseInactive(werehouse?.werehouse_id)}>{werehouse?.werehouse_status}</span>
                                                        )
                                                    }
                                                })()}
                                            </td>
                                            <td>
                                                <Link to={`/edit-ware-house/${werehouse?.werehouse_id}`} className="btnEdit"><AiFillEdit /></Link>
                                                <button className="btnDelete" onClick={() => handleWereHouseRemove(werehouse?.werehouse_id)}><AiFillDelete /></button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Company</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Country</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default WereHouse