import React from "react";
import { CDBSpinner } from 'cdbreact';

export default function LoadingSpinner() {
    return (
        <tr>
            <td colSpan={15}>
                <div className="spinner-container">
                    <CDBSpinner multicolor />
                </div>
            </td>
        </tr>
    );
}