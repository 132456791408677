import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { AiFillMobile, AiTwotoneMail } from 'react-icons/ai'
import { FaLocationDot, FaRankingStar, FaUserTie } from 'react-icons/fa6'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { useNavigate } from 'react-router-dom'
import { addFormData } from '../utils/api'
import { toast } from 'react-toastify'

const AddCompany = () => {
    const navigate = useNavigate();
    const [values, setUserValue] = useState();

    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleAddCompany = async (e) =>{
        e.preventDefault();
        if(values){
            try{
                addFormData('super-admin-add-company',values).then((res)=>{
                    if(res.data.status===1){
                        toast.success("Company has been added successfully!");
                        navigate('/company');
                    }
                    else if(res.data.status===2){
                        toast.warning("This Company already exit!!");
                    }
                    else{
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        else{
            toast.error("Server internal error!!");
        }
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:ADMIN::Add HR</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaUserTie className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD Company</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="post" onSubmit={handleAddCompany}>
                                <div className="col-12">
                                    <label htmlFor="company_name" className="form-label">Company Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaUserTie />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="company_name" name="company_name" placeholder="Company Name" onChange={handleInputs} required={true} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="company_mobile" className="form-label">Mobile No</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiFillMobile />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="company_mobile" name="company_mobile" placeholder="Mobile No" onChange={handleInputs} required={true} maxLength={10} minLength={10} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="company_email" className="form-label">Email Address</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiTwotoneMail />
                                        </span>
                                        <input type="email" className="form-control border-start-0" id="company_email" name="company_email" placeholder="Email Address" onChange={handleInputs} required={true} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="company_gst" className="form-label">GST Number</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaRankingStar />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="company_gst" name="company_gst" placeholder="GST Number" onChange={handleInputs} required={true} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="company_address" className="form-label">Company Address</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaLocationDot />
                                        </span>
                                        <textarea className="form-control border-start-0" id="company_address" name="company_address" placeholder="Company Address" onChange={handleInputs} required={true}>
                                        </textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddCompany