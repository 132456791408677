import React from 'react'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { AiOutlineUser } from 'react-icons/ai'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BarChartProductOrder from '../components/BarChartProductOrder'
import PieChartProductOrder from '../components/PieChartProductOrder'
import { Flat, Heat, Nested } from '@alptugidin/react-circular-progress-bar'
import BarChartHorizontaly from '../components/BarChartHorizontaly'

const Dashboard = () => {
    const navigate = useNavigate();
    const adminid = localStorage.getItem('admindata');
    useEffect(() => {
        if (adminid) {
            navigate('/dashboard');
        }
        else {
            navigate("/");
        }
    }, [adminid]);

    return (
        <div className="hrDashboard">
            <Helmet>
                <title>SATKARTAR:ADMIN::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 text-primary">641</h5>
                                    <div className="ms-auto">
                                        <AiOutlineUser />
                                    </div>
                                </div>
                                <div className="progress my-2">
                                    <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: "65%" }}></div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="mb-0">Today's Order Create</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 text-success">641</h5>
                                    <div className="ms-auto">
                                        <AiOutlineUser />
                                    </div>
                                </div>
                                <div className="progress my-2">
                                    <div className="progress-bar bg-success" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: "65%" }}></div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="mb-0">Today's Order Dispatched</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 text-danger">641</h5>
                                    <div className="ms-auto">
                                        <AiOutlineUser />
                                    </div>
                                </div>
                                <div className="progress my-2">
                                    <div className="progress-bar bg-danger" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: "65%" }}></div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="mb-0">Today's Order Cancle</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 text-secondary">641</h5>
                                    <div className="ms-auto">
                                        <AiOutlineUser />
                                    </div>
                                </div>
                                <div className="progress my-2">
                                    <div className="progress-bar bg-secondary" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: "65%" }}></div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="mb-0">Today's Product Return</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Graph */}
                <div className="row row-cols-1 row-cols-md-6 row-cols-xl-12 graphsection">
                    <div className="col-md-8 card barChartbody">
                        <div>
                            <BarChartProductOrder />
                        </div>
                    </div>
                    <div className="col-md-4 card polarCartBody">
                        <h6>Today's Sales</h6>
                        <div className="card-body">
                            <Nested
                                circles={[
                                    { text: 'Create', value: 80, color: '#fde047' },
                                    { text: 'Dispatched', value: 50, color: '#0ea5e9' },
                                    { text: 'Cancle', value: 35, color: '#c2410c' },
                                    { text: 'On Going', value: 25, color: '#7c3aed' },
                                    { text: 'Retrun', value: 10, color: '#c026d3' }
                                ]}
                                sx={{
                                    bgColor: '#cbd5e1'
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-6 row-cols-xl-12 graphsection yearsection">
                    <div className="col-md-8 card pieChartbody">
                        <div>
                            <BarChartHorizontaly />
                        </div>
                    </div>
                    <div className="col-md-4 card">
                        <div>
                            <PieChartProductOrder />
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Dashboard
