import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { AiFillMobile, AiTwotoneMail } from 'react-icons/ai'
import { FaCity, FaLocationDot } from 'react-icons/fa6'
import { FaRegHandPointRight } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addRegistration, editDataFromList, fetchHrDataFromSuperAdmin, getDataAPIToPincode, updateDataAPI } from '../utils/api'
import { FaHouseDamage } from 'react-icons/fa'
import { TbBuildingEstate, TbMapPinCode } from "react-icons/tb";
import { BiSolidHandRight } from "react-icons/bi";
import { GiIndiaGate } from "react-icons/gi";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

const initialState = {
    center_name: '',
    center_mobile: '',
    center_email: '',
    center_pincode: '',
    center_city: '',
    center_state: '',
    center_country: '',
    center_address: '',
    center_company: '',
}


const EditCenter = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);
    const [pindata, setPindata] = useState([]);
    const [hiddend, setHiddenValue] = useState({});
    const [company, setCompany] = useState([]);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        getSingleCenter(slug)
        getActiveCompany()
    }, [slug])

    const getActiveCompany = () => {
        fetchHrDataFromSuperAdmin('super-admin-get-active-company').then((res) => {
            if (res.data.status === 1) {
                setCompany(...[res.data.company])
            }
            else {
                toast.warning("Check you connection");
            }
        })
    }
    const getSingleCenter = (slug) => {
        editDataFromList(`super-admin-get-single-center-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setInputValues(...[res.data.centerdata])
            }
        })
    }
    const handlePincode = e => {
        if (e.target.value.length === 6) {
            const pincode = e.target.value;
            getDataAPIToPincode('super-admin-get-data-according-to-pincode', pincode).then((res) => {
                if (res?.data.status === 1) {
                    setPindata(...[res?.data.pincode]);
                    setHiddenValue({ ...hiddend, center_city: res?.data.pincode.city_name, center_state: res?.data.pincode.state_name, center_country: res?.data.pincode.country_name });
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
    }

    const handleInputs = async (e) => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleUpdateCenter = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            const data = { center_name: values.center_name, center_mobile: values.center_mobile, center_email: values.center_email, center_pincode: values.center_pincode, center_city: hiddend.center_city, center_state: hiddend.center_state, center_country: hiddend.center_country, center_address: values.center_address, center_company: values.center_company }
            updateDataAPI(`super-admin-update-center-data/${slug}`, data).then((res) => {
                if (res.data.status === 1) {
                    navigate("/all-center");
                    toast.success("Center has been updated successfully!!")
                }
                else if (res.data.status === 2) {
                    toast.warning("Center already Exit!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:SUPER ADMIN::Edit CENTER</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-12">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body formbody">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaHouseDamage className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">Edit Center</h5>
                            </div>
                            <hr />
                            <Form noValidate validated={validated} method="POST" onSubmit={handleUpdateCenter}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="validationCenterName">
                                        <Form.Label>Center Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaHouseDamage /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Center Name"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_name"
                                                onChange={handleInputs}
                                                value={values?.center_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid center name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterEmail">
                                        <Form.Label>Email</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><AiTwotoneMail /></InputGroup.Text>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter the email"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_email"
                                                onChange={handleInputs}
                                                value={values?.center_email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid center email.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterMobile">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><AiFillMobile /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the mobile number"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_mobile"
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                                onChange={handleInputs}
                                                value={values?.center_mobile}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid center mobile number.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterPincode">
                                        <Form.Label>Pincode</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><TbMapPinCode /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the pincode"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_pincode"
                                                pattern="[0-9]*"
                                                onKeyUp={handlePincode}
                                                onChange={handleInputs}
                                                value={values?.center_pincode}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid pincode.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomCity">
                                        <Form.Label>City</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaCity /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="City"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_city"
                                                readOnly={true}
                                                value={pindata?.city_name ? pindata?.city_name: values?.center_city}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid city.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomState">
                                        <Form.Label>State</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><TbBuildingEstate /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="State"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_state"
                                                readOnly={true}
                                                value={pindata?.state_name ? pindata?.state_name: values?.center_state}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid city.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomCountry">
                                        <Form.Label>Country</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><GiIndiaGate /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Country"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_country"
                                                readOnly={true}
                                                value={pindata?.country_name ? pindata?.country_name: values?.center_country}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid country.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterCompany">
                                        <Form.Label>Select Company</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><TbBuildingEstate /></InputGroup.Text>
                                            <Form.Select aria-label="Default select example" onChange={handleInputs} name="center_company" required>
                                                {company?.map((company, index) => (
                                                    (() => {
                                                        if (values?.center_company === company?.company_id) {
                                                            return (
                                                                <option selected value={company?.company_id} key={index}>{company?.company_name}</option>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <option value={company?.company_id} key={index}>{company?.company_name}</option>
                                                            )
                                                        }
                                                    })()
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid company name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCenterAddress">
                                        <Form.Label>Address</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaLocationDot /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter the full address"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_address"
                                                onChange={handleInputs}
                                                value={values?.center_address}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid address.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Row>
                                <Button type="submit">Update</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditCenter