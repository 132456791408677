import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { AiFillDelete, AiFillEdit, AiOutlinePlus } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { activeStatus, fetchHrDataFromSuperAdmin, removeDataFromList } from '../utils/api'
import LoadingSpinner from '../components/LoadingSpinner'
import Swal from 'sweetalert2'
import Skeleton from 'react-loading-skeleton'

const CenterHead = () => {
    const [center, setCenter] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAllCenter()
    }, [])

    const getAllCenter = () => {
        setIsLoading(true)
        fetchHrDataFromSuperAdmin('super-admin-all-center').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setCenter(...[res.data.centerdata]);
            }
            else {
                setIsLoading(false);
                toast.warning("Check your connection");
            }
        })
    }
    const handleCenterActive = (centerid) => {
        activeStatus('super-admin-active-center-status', centerid).then((res) => {
            if (res.data.status === 1) {
                getAllCenter()
            }
        })
    }
    const handleCenterInactive = (centerid) => {
        activeStatus('super-admin-inactive-center-status', centerid).then((res) => {
            if (res.data.status === 1) {
                getAllCenter()
            }
        })
    }
    const handleCenterRemove = (centerid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('super-admin-remove-center', centerid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getAllCenter()
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    return (
        <div className="companypage">
            <Helmet>
                <title>SATKARTAR:ADMIN::CENTER HEAD</title>
            </Helmet>
            <ContentWrapper>
                <div className="card hrpages">
                    <div className="heading d-flex justify-content-between">
                        <h5 className="mb-0 text-uppercase">All Center Head</h5>
                        <Link to="/add-center" className="btn btn-sm btn-primary pull-right"><AiOutlinePlus /> Add Center Head</Link>
                    </div>
                    <div className="table pagetable table-responsive">
                        <table id="example" className="table table-striped" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Company</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Country</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                    </>


                                    :
                                    center?.map((center, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{center?.center_name}</td>
                                            <td>{center?.center_email}</td>
                                            <td>{center?.center_mobile}</td>
                                            <td>{center?.company_name}</td>
                                            <td>{center?.center_city}</td>
                                            <td>{center?.center_state}</td>
                                            <td>{center?.center_country}</td>
                                            <td>
                                                {(() => {
                                                    if (center?.center_status === "Active") {
                                                        return (
                                                            <span className="badge bg-success hrstatus" onClick={() => handleCenterActive(center?.center_id)}>{center?.center_status}</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="badge bg-danger hrstatus" onClick={() => handleCenterInactive(center?.center_id)}>{center?.center_status}</span>
                                                        )
                                                    }
                                                })()}
                                            </td>
                                            <td>
                                                <Link to={`/edit-center/${center?.center_id}`} className="btnEdit"><AiFillEdit /></Link>
                                                <button className="btnDelete" onClick={() => handleCenterRemove(center?.center_id)}><AiFillDelete /></button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Company</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Country</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default CenterHead