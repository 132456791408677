import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { AiFillDelete, AiFillEdit, AiOutlinePlus } from 'react-icons/ai'
import Swal from 'sweetalert2'
import { activeStatus, fetchHrDataFromSuperAdmin, removeDataFromList } from '../utils/api'
import { toast } from 'react-toastify'
import LoadingSpinner from '../components/LoadingSpinner'
import Skeleton from 'react-loading-skeleton'

const SubAgent = () => {
    const [subagentdata, setSubagentdata] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const BASE_URLS = "https://backend.satkartar.org/assets/subagent/";

    useEffect(() => {
        getSubAgentData();
    }, [])
    const getSubAgentData = () => {
        setIsLoading(true);
        try {
            fetchHrDataFromSuperAdmin('super-admin-all-sub-agent').then((res) => {
                if (res.data.status === 1) {
                    setIsLoading(false)
                    setSubagentdata(...[res.data.agentdata]);
                }
                else {
                    toast.warning("Check your connection!!");
                    setIsLoading(false);
                }
            })
        }
        catch (error) {
            toast.error("Something Wrongs");
            setIsLoading(false);
        }
    }
    const handleSubAgentActive = (subagentid) => {
        activeStatus('super-admin-active-sub-agent-status', subagentid).then((res) => {
            if (res.data.status === 1) {
                getSubAgentData();
            }
        })
    }
    const handleSubAgentInactive = (subagentid) => {
        activeStatus('super-admin-inactive-sub-agent-status', subagentid).then((res) => {
            if (res.data.status === 1) {
                getSubAgentData();
            }
        })
    }
    const handleRemoveSubAgent = (subagentid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('super-admin-remove-sub-agent', subagentid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getSubAgentData();
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    return (
        <div className="hrpage">
            <Helmet>
                <title>SATKARTAR:ADMIN::SUB AGENT</title>
            </Helmet>

            <ContentWrapper>
                <div className="card hrpages">
                    <h4 className="mb-0 text-uppercase">
                        All Sub Agent
                        <Link to="/add-sub-agent" className="btn btn-sm btn-primary pull-right"><AiOutlinePlus /> Add Sub Agent</Link>
                    </h4>
                    <div className="table pagetable table-responsive">
                        <table id="example" className="table" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Last Login</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                    </>
                                    :
                                    subagentdata?.map((subagentdata, index) => (
                                        <tr key={subagentdata?.subagent_id}>
                                            <td>{index + 1}</td>
                                            <td><img width="50" height="50" style={{ width: "50px" }} src={BASE_URLS + subagentdata?.subagent_foto} alt={subagentdata?.subagent_foto} /></td>
                                            <td>{subagentdata?.subagent_name}</td>
                                            <td>{subagentdata?.subagent_email}</td>
                                            <td>{subagentdata?.subagent_mobile}</td>
                                            <td>{subagentdata?.subagent_last_login}</td>
                                            <td>
                                                {(() => {
                                                    if (subagentdata?.subagent_status === "Active") {
                                                        return (
                                                            <span className="badge bg-success hrstatus" onClick={() => handleSubAgentActive(subagentdata?.subagent_id)}>Active</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="badge bg-danger hrstatus" onClick={() => handleSubAgentInactive(subagentdata?.subagent_id)}>Inactive</span>
                                                        )
                                                    }
                                                })()}
                                            </td>
                                            <td>
                                                <Link to={`/edit-sub-agent/${subagentdata?.subagent_id}`} className="btnEdit"><AiFillEdit /></Link>
                                                <button className="btnDelete" onClick={() => handleRemoveSubAgent(subagentdata?.subagent_id)}><AiFillDelete /></button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Last Login</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ContentWrapper>

        </div>
    )
}

export default SubAgent