import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { AiFillDelete, AiFillEdit, AiOutlinePlus } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { activeStatus, fetchHrDataFromSuperAdmin, removeDataFromList } from '../utils/api'
import LoadingSpinner from '../components/LoadingSpinner'
import Swal from 'sweetalert2'

const Company = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState([]);


    useEffect(() => {
        getCompany();
    }, []);

    const getCompany = () => {
        setIsLoading(true);
        try {
            fetchHrDataFromSuperAdmin('super-admin-all-company').then((res) => {
                if (res.data.status === 1) {
                    setCompany(...[res.data.company]);
                    setIsLoading(false)
                }
                else {
                    setIsLoading(false);
                }
            })
        }
        catch (error) {
            setIsLoading(false);
        }
    }
    const handleCompanyActive = (hrid) => {
        activeStatus('super-admin-active-company-status', hrid).then((res) => {
            if (res.data.status === 1) {
                getCompany();
            }
        })
    }
    const handleCompanyInactive = (hrid) => {
        activeStatus('super-admin-inactive-company-status', hrid).then((res) => {
            if (res.data.status === 1) {
                getCompany();
            }
        })
    }
    const handleHrRemoveHr = (hrid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('super-admin-remove-company', hrid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getCompany();
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    return (
        <div className="companypage">
            <Helmet>
                <title>SATKARTAR:ADMIN::COMPANY</title>
            </Helmet>

            <ContentWrapper>
                <div className="card hrpages">
                    <h4 className="mb-0 text-uppercase">
                        All Company
                        <Link to="/add-company" className="btn btn-primary pull-right"><AiOutlinePlus /> Add Company</Link>
                    </h4>
                    <div className="table pagetable table-responsive">
                        <table id="example" className="table table-striped" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>GST Number</th>
                                    <th>Address</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? <LoadingSpinner />
                                    :
                                    company?.map((company, index) => (
                                        <tr key={company?.company_id}>
                                            <td>{index + 1}</td>
                                            <td>{company?.company_name}</td>
                                            <td>{company?.company_email}</td>
                                            <td>{company?.company_mobile}</td>
                                            <td>{company?.company_gst}</td>
                                            <td>{company?.company_address}</td>
                                            <td>
                                                {(() => {
                                                    if (company?.company_status === "Active") {
                                                        return (
                                                            <span className="badge bg-success hrstatus" onClick={() => handleCompanyActive(company?.company_id)}>Active</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="badge bg-danger hrstatus" onClick={() => handleCompanyInactive(company?.company_id)}>Inactive</span>
                                                        )
                                                    }
                                                })()}
                                            </td>
                                            <td>
                                                <Link to={`/edit-company/${company?.company_id}`} className="btnEdit"><AiFillEdit /></Link>
                                                <button className="btnDelete" onClick={() => handleHrRemoveHr(company?.company_id)}><AiFillDelete /></button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>GST Number</th>
                                    <th>Address</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Company