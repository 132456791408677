import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables);

const BarChartProductOrder = () => {
    const [data] = useState({
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        datasets: [
            {
                label: 'Order Create',
                backgroundColor: '#001633',
                borderColor: 'rgb(194, 116, 161)',
                data: [65, 59, 90, 81, 56, 55, 100],
            },
            {
                label: 'Order Dispatch',
                backgroundColor: '#85FFD0',
                borderColor: 'rgb(71, 225, 167)',
                data: [48, 78, 10, 99, 46, 87, 20],
            },
            {
                label: 'Cancle Orders',
                backgroundColor: '#da2f68',
                borderColor: 'rgb(71, 225, 167)',
                data: [38, 68, 35, 49, 76, 57, 70],
            },
            {
                label: 'product Returns',
                backgroundColor: '#ff5733',
                borderColor: 'rgb(71, 225, 167)',
                data: [28, 48, 50, 89, 36, 97, 10],
            },
        ],
    });
    return (
        <div className="bg-white">
            <h4>Weekly Sales</h4>
            <Bar data={data} options={{ responsive: true }} />
        </div>
    )
}

export default BarChartProductOrder