import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { AiFillMobile, AiTwotoneMail } from 'react-icons/ai'
import { useNavigate, useParams } from 'react-router-dom'
import { editDataFromList, updateDataAPI } from '../utils/api'
import { toast } from 'react-toastify'
import { FaUserTie } from "react-icons/fa6";

const initialState = {
    hr_name: '',
    hr_mobile: '',
    hr_email: '',
}

const EditHr = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);

    useEffect(() => {
        getHrData(slug)
    }, [slug]);

    const getHrData = (slug) => {
        editDataFromList(`super-admin-get-single-hr-data/${slug}`).then((res)=>{
            if(res.data.status===1){
                setInputValues(...[res.data.hrdata])
            }
        })
    }
    const updateHrData = async (e) => {
        e.preventDefault();
        try {
            updateDataAPI(`super-admin-update-hr-data/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    navigate('/hr');
                    toast.success("Hr Data has been updated successfully!!");
                }
                else {
                    toast.error("Something Wrongs!!");
                }
            })
        }
        catch (error) {
            toast.error("Connection failed!!")
        }
    }
    const handleInputs = e => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <div className="addHrPanel">
            <Helmet>
                <title>SATKARTAR:ADMIN::Edit HR</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaUserTie className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">EDIT HR</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="post" onSubmit={updateHrData}>
                                <div className="col-12">
                                    <label htmlFor="hr_name" className="form-label">Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaUserTie />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="hr_name" name="hr_name" placeholder="Full Name" required={true} value={values.hr_name} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="hr_mobile" className="form-label">Mobile No</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiFillMobile />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="hr_mobile" name="hr_mobile" placeholder="Mobile No" required={true} value={values.hr_mobile} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="hr_email" className="form-label">Email Address</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiTwotoneMail />
                                        </span>
                                        <input type="email" className="form-control border-start-0" id="hr_email" name="hr_email" placeholder="Email Address" required={true} value={values.hr_email} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditHr