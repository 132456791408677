import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { AiFillMobile, AiTwotoneMail } from 'react-icons/ai'
import { FaCity, FaLocationDot } from 'react-icons/fa6'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { editDataFromList, fetchHrDataFromSuperAdmin, getDataAPIToPincode, updateDataAPI } from '../utils/api'
import { FaHouseDamage } from 'react-icons/fa'
import { TbBuildingEstate, TbMapPinCode } from "react-icons/tb";
import { GiIndiaGate } from "react-icons/gi";

const initialState = {
    whouse_name: '',
    whouse_mobile: '',
    whouse_email: '',
    whouse_pincode: '',
    whouse_city: '',
    whouse_state: '',
    whouse_country: '',
    whouse_address: '',
    whouse_company: '',

}

const EditWereHouse = () => {
    const formRef = React.useRef(null);
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);

    const [pindata, setPindata] = useState([]);
    const [hiddend, setHiddenValue] = useState({});
    const [company, setCompany] = useState([]);

    useEffect(() => {
        getWerehouse(slug)
        getActiveCompany()
    }, [slug])

    const getWerehouse = (slug) => {
        editDataFromList(`super-admin-get-single-werehouse-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setInputValues(...[res.data.werehouse])
            }
        })
    }
    const getActiveCompany = () => {
        fetchHrDataFromSuperAdmin('super-admin-get-active-company').then((res) => {
            if (res.data.status === 1) {
                setCompany(...[res.data.company])
            }
            else {
                toast.warning("Check you connection");
            }
        })
    }

    const handlePincode = e => {
        if (e.target.value.length === 6) {
            const pincode = e.target.value;
            getDataAPIToPincode('super-admin-get-data-according-to-pincode', pincode).then((res) => {
                if (res?.data.status === 1) {
                    setPindata(...[res?.data.pincode]);
                    setHiddenValue({ ...hiddend, whouse_city: res?.data.pincode.city_name, whouse_state: res?.data.pincode.state_name, whouse_country: res?.data.pincode.country_name });
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
    }

    const handleInputs = async (e) => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleUpdateWHouse = async (e) => {
        e.preventDefault();
        const data = { whouse_name: values.whouse_name, whouse_email: values.whouse_email, whouse_pincode: values.whouse_pincode, whouse_city: hiddend.whouse_city, whouse_state: hiddend.whouse_state, whouse_country: hiddend.whouse_country, whouse_address: values.whouse_address, whouse_company: values.whouse_company }
        if (data) {
            updateDataAPI(`super-admin-update-werehouse-data/${slug}`, data).then((res) => {
                if (res.data.status === 1) {
                    navigate("/ware-house");
                    toast.success("Ware house has been updated successfully!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        else {
            console.log("all input values errors");
        }
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:SUPER ADMIN::Edit WERE HOUSE</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-12">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaHouseDamage className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">Edit Were House</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="POST" onSubmit={handleUpdateWHouse} ref={formRef}>
                                <div className="col-6">
                                    <label htmlFor="whouse_name" className="form-label">Were House Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaHouseDamage />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="whouse_name" name="whouse_name" placeholder="Were House Name" onChange={handleInputs} required={true} value={values.whouse_name} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_mobile" className="form-label">Mobile No</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiFillMobile />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="whouse_mobile" name="whouse_mobile" placeholder="Mobile No" maxLength={10} minLength={10} inputMode="numeric" required={true} value={values.whouse_mobile} readOnly={true} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_email" className="form-label">Email Address</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiTwotoneMail />
                                        </span>
                                        <input type="email" className="form-control border-start-0" id="whouse_email" name="whouse_email" placeholder="Email Address" onChange={handleInputs} required={true} value={values.whouse_email} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_pincode" className="form-label">Pincode</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <TbMapPinCode />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="whouse_pincode" name="whouse_pincode" placeholder="Pincode" onKeyUp={handlePincode} onChange={handleInputs} maxLength={6} minLength={6} required={true} inputMode="numeric" pattern="[0-9]{6}" value={values.whouse_pincode} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_city" className="form-label">City Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaCity />
                                        </span>
                                        {(() => {
                                            if (pindata?.city_name) {
                                                return (
                                                    <input type="text" className="form-control border-start-0" id="whouse_city" name="whouse_city" placeholder="City Name" value={pindata?.city_name} required={true} readOnly={true} />
                                                )
                                            }
                                            else {
                                                return (
                                                    <input type="text" className="form-control border-start-0" id="whouse_city" name="whouse_city" placeholder="City Name" value={values?.whouse_city} required={true} readOnly={true} />
                                                )
                                            }
                                        })()}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_state" className="form-label">State Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <TbBuildingEstate />
                                        </span>
                                        {(() => {
                                            if (pindata?.state_name) {
                                                return (
                                                    <input type="text" className="form-control border-start-0" id="whouse_state" name="whouse_state" value={pindata?.state_name} placeholder="State Name" required={true} readOnly={true} />
                                                )
                                            }
                                            else {
                                                return (
                                                    <input type="text" className="form-control border-start-0" id="whouse_state" name="whouse_state" value={values?.whouse_state} placeholder="State Name" required={true} readOnly={true} />
                                                )
                                            }
                                        })()}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_country" className="form-label">Country Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <GiIndiaGate />
                                        </span>
                                        {(() => {
                                            if (pindata?.country_name) {
                                                return (
                                                    <input type="text" className="form-control border-start-0" id="whouse_country" name="whouse_country" value={pindata?.country_name} placeholder="Country Name" required={true} readOnly={true} />
                                                )
                                            }
                                            else {
                                                return (
                                                    <input type="text" className="form-control border-start-0" id="whouse_country" name="whouse_country" value={values?.whouse_country} placeholder="Country Name" required={true} readOnly={true} />
                                                )
                                            }
                                        })()}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="whouse_country" className="form-label">Select Company</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <TbBuildingEstate />
                                        </span>
                                        <select className="form-control border-start-0" id="whouse_company" name="whouse_company" required={true} onChange={handleInputs}>
                                            {company?.map((company) => ((() => {
                                                if (company.company_id === values.whouse_company) {
                                                    return (
                                                        <option value={company.company_id} selected={true}>{company.company_name}</option>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <option value={company.company_id}>{company.company_name}</option>
                                                    )
                                                }
                                            })()))}

                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="whouse_address" className="form-label">Address</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaLocationDot />
                                        </span>
                                        <textarea className="form-control border-start-0" id="whouse_address" name="whouse_address" placeholder="Address" onChange={handleInputs} required={true} value={values.whouse_address}>
                                        </textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditWereHouse