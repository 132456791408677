import React, { useState } from 'react'
import { addHrRegistration } from '../utils/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaUserTie } from 'react-icons/fa6';
import { Helmet } from 'react-helmet';
import ContentWrapper from '../components/contentwrapper/ContentWrapper';
import { AiFillMobile, AiTwotoneMail } from 'react-icons/ai';

const AddMis = () => {
    const navigate = useNavigate();
    const [values, setUserValue] = useState();

    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleMisRegistration = async (e) =>{
        e.preventDefault();
        if(values){
            try{
                addHrRegistration('super-admin-add-mis',values).then((res)=>{
                    if(res.data.status===1){
                        toast.success("MIS has been added successfully!");
                        navigate('/mis');
                    }
                    else if(res.data.status===2){
                        toast.warning("This MIS already exit!!");
                    }
                    else{
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        else{
            toast.error("Server internal error!!");
        }
    }

    return (
        <div className="addHrPanel">
            <Helmet>
                <title>SATKARTAR:ADMIN::Add MIS</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaUserTie className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD MIS</h5>
                            </div>
                            <hr />
                            <form className="row g-3" onSubmit={handleMisRegistration} method="post">
                                <div className="col-12">
                                    <label htmlFor="mis_name" className="form-label">Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaUserTie />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="mis_name" name="mis_name" placeholder="Full Name" onChange={handleInputs} required={true} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="mis_mobile" className="form-label">Mobile No</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiFillMobile />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="mis_mobile"inputMode="numeric" name="mis_mobile" placeholder="Mobile No" minLength={10} maxLength={10} onChange={handleInputs} required={true} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="mis_email" className="form-label">Email Address</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiTwotoneMail />
                                        </span>
                                        <input type="email" className="form-control border-start-0" id="mis_email" name="mis_email" placeholder="Email Address" onChange={handleInputs} required={true} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Register</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddMis