import React, { useState } from 'react';
import { PolarArea } from 'react-chartjs-2';
import { CDBContainer } from 'cdbreact';

const PieChartProductOrder = () => {
    const [data] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
            {
                label: 'Order Create',
                backgroundColor: 'rgba(194, 116, 161, 0.5)',
                borderColor: 'rgb(194, 116, 161)',
                data: [65, 59, 90, 81, 56, 55, 40, 90, 81, 56, 55, 40],
            },
            {
                label: 'Order Dispatch',
                backgroundColor: 'rgba(71, 225, 167, 0.5)',
                borderColor: 'rgb(71, 225, 167)',
                data: [28, 48, 40, 19, 96, 27, 100, 90, 81, 56, 55, 40],
            },
        ],
    });
    return (
        <CDBContainer className="bg-white">
            <h4>Sales</h4>
            <PolarArea data={data} options={{ responsive: true }} />
        </CDBContainer>
    )
}

export default PieChartProductOrder